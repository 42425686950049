@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~react-image-gallery/styles/css/image-gallery.css";

.ais-SearchBox-form:before {
background: transparent !important;
}
.signup-blue-button{
    background-color: #5B89C7;
}
.full-width{
    width: 100% !important;
}
#background-city-video{
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
}
#google-login-btn-span div{
    background-color: red;
    border-radius: 20px !important;
}
#google-login-btn-span div[type="dark"]{
    min-width: 150px;
}
#google-login-btn-span div[type="dark"] div{
    height: auto !important;
    width: auto !important;
}