

.rating {
    padding: 2em;
    display: flex;
}

.read-the-docs {
    color: #888;
}

input[type=radio]{
    display: none;
}

.star{
    cursor: pointer;
}